<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-05-25 22:53:42
 * @LastEditTime: 2021-05-27 14:26:29
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="notice">
    <SonTitle title="公司公告"></SonTitle>
    <NewsCard
      v-for="item in list"
      :key="item.id"
      :info="item"
      source="Notice"
    ></NewsCard>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.$api.getNotice().then((res) => {
        this.list = res.data.data
      })
    }
  },
}
</script>

<style scoped lang='scss'>
</style>